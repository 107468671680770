<template>
  <div class="holder">
    <div :class="`card-holder card-holder-${direction} ${className}`">
      <vue-flip :width="String(cardWidth)" :height="String(cardHeight)" transition="1s" v-model="flipped" class="cardx">
        <template v-slot:front>
          <div>
            <card-front :width="cardWidth" :height="cardHeight" :img="card.back"></card-front>
          </div>
        </template>
        <template v-slot:back>
          <div>
            <card-front :width="cardWidth" :height="cardHeight" :img="card.front" :card="card"></card-front>
          </div>
        </template>
      </vue-flip>
      <div class="info-box">
        Zadej kód a vyhraj na
        <div class="info-box-url">catchyourgalaxy.cz</div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueFlip from 'vue-flip';
  import CardFront from '../components/Contest/CardFront.vue'
  import Pusher from 'pusher-js';
  Pusher.logToConsole = true;

  export default {
    name: 'Overlay',
    data: () => ({
      card: {},
      cardWidth: 256,
      cardHeight: 405,
      direction: null,
      flipped: false,
      className: null,
      delay: 0,
      pusher: null
    }),
    components: {
        CardFront,
        VueFlip
    },
    methods: {
      show: function(card) {
        this.card = card
        
        this.start();
      },
      reset: function() {
        this.flipped = false;
        this.className = null;
      },
      start: function() {
        // slide in
        setTimeout(() => this.className = 'active', this.delay+0)

        // shake
        setTimeout(() => this.className = 'active shake', this.delay+2000)

        // flip
        setTimeout(() => this.flipped = true, this.delay+4000)

        // slide out & reset
        setTimeout(() => this.reset(), ((this.card.card_show_duration || 5)*1000)+this.delay+6000)
        
      }
    },
    mounted: function() {
      // init pusher
      this.direction = this.$route.params.direction
      
      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_ID, {
        cluster: 'eu',
        authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT
      });
      
      console.log(this.pusher)
      this.pusherChannel = this.pusher.subscribe(`private-${this.$route.params.token}`);

      this.pusherChannel.bind('card:show', (message) => this.show(message.card))
      

      this.start();
    }
  }
</script>

<style>
:root {
  --card-width: 256px;
  --card-height: 405px;
}
  html, body, .v-application { background-color: rgba(0,0,0,0)!important; overflow: hidden; }
  .holder {
    width: 100%;
    height: 100%;
    position: relative;
    color: white;
    overflow: hidden;
  }
  .card-holder {
    position: absolute;
    width: var(--card-width);
    height: calc(var(--card-height) + 90px);
    transition: right 2s, left 2s, top 2s, bottom 2s;
  }
  .card-holder-right {
    right: calc(0px - var(--card-width) - 20px);
  }
  .card-holder-right.active {
    right: 0px;
  }
  
  .card-holder-left {
    left: calc(0px - var(--card-width) - 20px);
  }
  .card-holder-left.active {
    left: 0px;
  }

  .card-holder-top {
    top: calc(0px - var(--card-height) - 110px);
  }
  .card-holder-top.active {
    top: 0px;
  }

  .card-holder-bottom {
    bottom: calc(0px - var(--card-height) - 110px);
  }
  .card-holder-left .flipper {
    margin-left: 1em;
  }
  .card-holder-right .flipper {
    right: 1em;
  }
  .card-holder-bottom.active {
    bottom: 0px;
  }


  .card-holder.shake .cardx {
    animation: shake 0.5s;
    animation-iteration-count: 4
  }

  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

  .card-holder .info-box {
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: rgb(26, 26, 26);
    color: white;
    font-size: 1.2em;
    text-align: center;
    padding: 0.5em;
    
  }
  .card-holder-left .info-box, .card-holder-right .info-box {
    width: calc(100% + 1em);
  }
  .card-holder-top .info-box {
    border-radius: 10px;
  }
  .card-holder-bottom .info-box {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .card-holder-left .info-box {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .card-holder-right .info-box {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    right: 0;
  }
  .card-holder .info-box-url {
    font-weight: bold;
  }
  .card-front-content .name {
    font-size: 1.7em !important;
    overflow: hidden;
  }
  .card-front-content .code {
    font-size: 2em !important;
  }
</style>
